.hp {
  .instagram-feed {
    background-color:$black0;
    padding-bottom:.25rem;
    @include breakpoint (medium) {
      padding-bottom:1rem;
    }
  }

  .instagram-container {
    list-style: none;

    img {
      width: 100%;
    }

    a {
      padding-bottom: 100%;
      display: block;
      background-position: center;
      background-size: cover;
    }
  }

  .instagram-header {
    background-color: $black0;
    font-family:$regular-font-family;
    font-weight:500;
    color: $white;
    font-size: .85rem;
    text-align: center;
    padding:.75rem 0;
    margin: 0;
    text-transform: none;
    @include breakpoint (medium) {
      font-size:1.25rem;
      padding: 1rem 0;
    }
    a {
      display:flex;
      align-items: center;
      justify-content: center;
    }
    i {
      font-size:1.25rem;
      margin-left:.5rem;
      margin-right:.35rem;
      @include breakpoint (medium) {
        font-size:2rem;
        margin-left:1rem;
        margin-right:.5rem;
      }
    }
  }

  #instafeed .column {
    padding:0;
  }

  .instagram-popup {
    max-width: 80rem;
    padding: 0;
    border-radius: 3px;
    font-weight: 400;

    >div {
      margin: 0;
    }

    &-img {
      width: 100%;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &-profilepic {
      border-radius: 2px;
    }

    &-page {
      display: block;
      position: relative;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0);
        z-index: 1;
      }

      &:hover:before {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &-link {
      color: $white;
      display: block;
      padding: 5px;
      text-transform: uppercase;
      text-align: center;
      font-size: 0.9rem;
      background-color: $primary-color;
      border-radius: 2px;
    }

    .no-padding {
      padding: 0;
    }
  }
}