.hp .packages {
  background-image:url('/images/home/packages.jpg');
  background-position: center;
  background-size:cover;
  text-align:center;
  .content {
    color:$white;
    text-transform: uppercase;
    line-height:1;
    padding-top:4rem;
    padding-bottom:4rem;
    @include breakpoint (large) {
      padding-top:9rem;
      padding-bottom:8.25rem;
    }
  }
  .title {
    font-family:$black-font-family;
    font-weight:700;
    line-height:.85;
    font-size:2.25rem;
    margin-bottom:.75rem;
    @include breakpoint (medium) {
      font-size:4rem;
    }
    @include breakpoint (large) {
      font-size:6.5rem;
      margin-bottom:2.5rem;
    }
    span {
      display:block;
      font-size:5rem;
      @include breakpoint (medium) {
        font-size:8rem;
      }
      @include breakpoint (large) {
        font-size:13rem;
      }
    }
  }
  .subtitle {
    font-family:$regular-font-family;
    letter-spacing:.1rem;
    font-weight:400;
    margin-bottom:1.75rem;
    @include breakpoint (medium) {
      font-size:1.5rem;
    }
    @include breakpoint (large) {
      font-size:2.25rem;
      letter-spacing:.25rem;
      margin-bottom:3.5rem;
    }
  }
  a {
    color:$white;
    font-weight:700;
    letter-spacing:.1rem;
    border:1px solid $white;
    display:flex;
    justify-content: center;
    align-items: center;
    margin:0 auto;
    width:104px;
    height:30px;
    font-size:.75rem;
    @include breakpoint (medium) {
      font-size:1.25rem;
      width:206px;
      height:60px;
    }
    @include breakpoint (large) {
      font-size:1.75rem;
      width:260px;
      height:74px;
    }
    &:hover {
      background-color:$primary-color-transparent;
    }
  }
}