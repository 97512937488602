#products {
  .parallax {
    margin-bottom:0;
  }
  .content-header {
    @include breakpoint (medium) {
      //background-image:url('/images/banners/suspension.jpg');
      background-image:url('//images.iconfigurators.app/images/users/richlinemotorsports/accessories.jpg');
      background-size:cover;
      background-position: center;
      text-align:center;
      padding-top:2rem;
      padding-bottom:2rem;
      display:flex;
      align-items: center;
      justify-content: center;
    }
    @include breakpoint (large) {
      height:240px;
    }
    h1 {
      text-transform:uppercase;
      @include breakpoint (medium) {
        color:$white;
      }
      @include breakpoint (large) {
        font-size:2.25rem;
        font-weight:700;
        letter-spacing:.2rem;
      }
    }
  }
}
.product-vehicle-bar {
  padding-top:.5rem;
  padding-bottom:.5rem;
  text-align:center;
  background-color:$primary-color;
  margin-bottom:2rem;
  font-size:1rem;
  h2 {
    margin-bottom:0;
    font-size:1rem;
    text-transform: uppercase;
    color:$white;
  }
  @include breakpoint (large) {
    padding-top:1rem;
    padding-bottom:1rem;
    h2 {
      font-size:2rem;
    }
  }
}
.products-listing {
  h6 {
    margin-bottom: 0;
    font-family:$body-font-family;
    font-weight:600;
    line-height:1.25;
    letter-spacing:0;
  }
  .product-item {
    text-align:center;
    border-bottom:1px solid $gray4;
    margin-bottom:2rem;
    min-height: 400px;
  }
  .product-image {
    min-height:200px;
    padding-bottom:1rem;
    position:relative;
    @include breakpoint (large) {
      padding-bottom:2rem;
    }
    img {
      max-height:200px;
    }
    .in-stock-image {
      max-width:50px;
      position: absolute;
      top:0;
      left:0;
    }
  }
  .product-name {
    min-height:40px;
    /*max-height:40px;*/
  }
  .product-notes,.product-price {
    color:$black;
    padding-bottom:1rem;
  }
  .product-notes {
    font-weight:600;
    line-height:1.25;
  }
  .product-number {
    color:$gray3;
    font-weight:800;
    line-height:1.5;
  }
  .product-price {
    margin-top:1rem;
  }

  // specials
    .pricing {
      color:$gray54;
      font-weight:600;
      .price,.sale-price {
        font-weight:400;
      }
      .price {
        //text-decoration: line-through;
      }
      .sale-price {
        margin-left:.25rem;
      }
      .sale-box {
        background-color:$secondary-color;
        color:$black;
        padding:.25rem;

        display:inline-block;
        padding:.25rem .5rem;
        line-height:1.5;
        margin-bottom:.5rem;
      }
    }
}

.loading-screen {
  background-color:rgba(0,0,0,.7);
  position:fixed;
  top:0;
  right:0;
  left:0;
  bottom:0;
  content:'';
  display:flex;
  z-index:9999;
  align-items:center;
  justify-content: center;
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 45px;  
    font-size:3rem;
    color:$white;
    -webkit-animation:spin 1s linear infinite;
    -moz-animation:spin 1s linear infinite;
    animation:spin 1s linear infinite;
  }
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
}


.column-listing {
  a {
    display:block;
  }
}