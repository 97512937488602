.hp .cta {
  padding-top:1.5rem;
  @include breakpoint (medium) {
    padding-top:3rem;
    padding-bottom:3rem;
  }
  @include breakpoint (large) {
    padding-top:5rem;
    padding-bottom:5rem;
  }
  .column {
    margin-bottom:1.5rem;
    padding-left:1.5rem;
    padding-right:1.5rem;
    @include breakpoint (medium) {
      margin-bottom:0;
    }
    @include breakpoint (large) {
      padding:0;
    }
    &:first-child {
      a {
        background-image:url('/images/home/cta/wheels.jpg');
      }
      @include breakpoint (medium) {
        padding-right:1.5rem;
      }
    }
    &:last-child {
      a {
        background-image:url('/images/home/cta/tires.jpg');
      }
      @include breakpoint (medium) {
        padding-left:1.5rem;
      }
    }
  }
  a {
    background-size:100%;
    background-position:center;
    letter-spacing:.1rem;
    display:block;
    text-transform: uppercase;
    color:$white;
    font-weight:500;
    line-height:1;
    text-align:center;
    padding-top:4rem;
    padding-bottom:3.5rem;
    @include breakpoint (large) {
      padding-top:8rem;
      padding-bottom:4rem;
    }
    &:hover {
      background-size:105%;
      .link {
        background-color:$primary-color-transparent;
      }
    }
  }
  .subtitle {
    font-family:$regular-font-family;
    font-size:1rem;
    margin-bottom:.25rem;
    @include breakpoint (large) {
      font-size:1.25rem;
      margin-bottom:.75rem;
    }
  }
  .title {
    font-family:$black-font-family;
    font-size:4.5rem;
    font-weight:700;
    margin-bottom:1rem;
    @include breakpoint (large) {
      font-size:7rem;
      margin-bottom:1.75rem;
    }
  }
  .link {
    font-size:.85rem;
    display:flex;
    justify-content: center;
    align-items: center;
    font-weight:600;
    border:1px solid $white;
    margin:0 auto;
    width:80px;
    height:40px;
    @include breakpoint (large) {
      width:130px;
      height:60px;
      font-size:1rem;
    }
  }
}