.details-information {
  font-size:1rem;
  h2 {
    font-family:$heading-font-family;
    font-size:1.75rem;
    text-transform: uppercase;
    margin-bottom:0;
  }
  > div {
    padding-bottom:.5rem;
  }
  .label {
    background-color:transparent;
    font-weight:700;
    text-transform:capitalize;
    color:$black;
    font-size:1rem;
    padding-left:0;
  }
  .details-brand-logo {
    padding-bottom:1.5rem;
  }
  .title {
    //padding-bottom:1.5rem;
    h3 {
      font-family:$body-font-family;
      font-size:1.35rem;
      margin-bottom:0;
      text-transform: none;
    }
  }
  .product-vehicle {
    h3 {
      font-family:$body-font-family;
      font-size:1.35rem;
      color:$gray6;
      margin-bottom:0;
      text-transform: none;
    }
  }
  .product-information {
    font-size:.9rem;
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:1.5rem;
    }
  }
  .instock {
    font-family:$heading-font-family;
    text-transform: uppercase;
    font-weight:600;
    font-size:1.25rem;
    &.orange {
      //color:$orange;
      color:$gray3;
    }
    &.green {
      color:$green;
    }
  }
  .part-number .label {
    text-transform: uppercase;
  }

  .pricing-affirm {
    background-color:$grayEF;
    padding:1rem;
    border-radius:.5rem;
    color:$black;
    margin-bottom:1rem;
    font-size:.9rem;
    text-align:center;
    font-weight:500;
    max-width:400px;
    .affirm-modal-trigger {
      display:inline-block;
      font-weight:600;
    }
    p {
      margin:0;
    }
  }
  
  .price {
    font-size:2rem;
    .content {
      font-weight:900;
      color:$black;
    }
    .sale {
      text-decoration:line-through;
      &Price { 
        margin-left:.5rem;
      }
    }
    .sale-box {
      background-color:$primary-color;
      color:$black;
      padding:.25rem;
      margin-left:.5rem;
      font-weight:400;
      font-size:1rem;
    }
  }
  .quantity {
    display:flex;
    align-items: center;
    padding-bottom:1rem;
    input {
      max-width:2.5rem;
      max-height:1.5rem;
      padding:0 .25rem;
      margin-bottom:0;
    }
  }
  .vehicle {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    border-top:1px solid $gray9;
    border-bottom:1px solid $gray9;
    padding-top:1rem;
    padding-bottom:1rem;
    margin-bottom:1rem;
    &-icon {
      background-color:$primary-color;
      display:flex;
      justify-content: center;
      align-content: center;
      border-radius: 50px;
      width:100%;
      max-width:50px;
      height:50px;
      margin-right:1rem;
      i {
        color:$white;
        display: flex;
        align-items: center;
        font-size:2rem;
      }
    }
    &-information {
      font-weight:800;
      font-size:2rem;
      color:$black;
      line-height: 1;
    }
  }
  .shipping {
    padding-bottom:1rem;
  }
  .vehicle-info {
    background-color:$grayE;
    padding:1rem;
    display:none;
    label {
      text-transform: capitalize;
      font-size:1rem;
      font-weight:700;
    }
    input {
      &.missing {
        background-color:rgba(255, 51, 51, 0.5);
      }
    }
    .snipcart-add-item {
      display:none;
    }
    .button {
      &.disabled {
        color:$white;
        background-color:$gray3;
      }
    }
  }
  .buy-toggle.buy-active {
    background-color:$gray9;
    color:$gray3;
  }
  .button {
    //color:$black;
    &:hover {
      //color:$orange;
      color:$gray3;
    }
  }
  .financing-options {
    padding-top:1rem;
    margin-bottom:1rem;
  }

  .shipping-options {
    border-top:1px solid $gray9;
    border-bottom:1px solid $gray9;
    padding-top:1rem;
    padding-bottom:1rem;
    margin-top:1rem;
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:2rem;
    }
    .free-shipping {
      display:flex;
      align-items:center;
      .icon {
        margin-right:.75rem;
        font-size:2rem;
        color:$green;
      }
      .information {
        line-height:1.35;
        font-size:.9rem;
      }
    }
  }
}