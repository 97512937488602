.hp .featured {
  background-image:url('/images/home/featured/featured.jpg');
  background-size:cover;
  background-position: center;
  text-align:center;
  @include breakpoint (large) {
    padding-top:7rem;
    padding-bottom:5rem;
  }
  a {
    background-size:cover;
    background-position: center;
    color:$white;
    display:block;
    text-transform: uppercase;
    letter-spacing:.1rem;
    line-height:1;
    border:.5rem solid $white;
    @include breakpoint (medium) {
      border-width:.75rem;
    }
    &:hover {
      .content {
        background-color:rgba(255,0,0,.7);
      }
    }
  }
  .subtitle {
    font-family:$regular-font-family;
    font-weight:500;
    margin-bottom:1.5rem;
    @include breakpoint (small only) {
      font-size:.75rem;
      margin-bottom:.75rem;
    }
  }
  .title {
    font-family:$black-font-family;
    font-size:1.5rem;
    font-weight:700;  
    @include breakpoint (medium) {
      font-size:2.25rem;
    }
  }
  .row > .column {
    padding:0;
  }
  .content {
    padding:2rem .25rem 2.25rem;
    background-color:rgba(0,0,0,0.7);
    transition:all .2s ease-in-out;
    @include breakpoint (medium) {
      padding:2.75rem .25rem 4rem;
    }
  }
  .column {
    .column {
      padding:0;
    }
    &.left {
      padding:1rem;
      @include breakpoint (large) {
        padding:0 .5rem 0 0;
      }
      a {

      }
      .column {
        padding:.5rem;
        @include breakpoint (large) {
          padding:0;
          padding-right:1.5rem;
          margin-bottom:1.5rem;
        }
        &:first-child {
          a {
            background-image:url('/images/home/featured/suspension.jpg');
          }  
        }
        &:nth-child(2) {
          a {
            background-image:url('/images/home/featured/wheel.jpg');
          }   
        }
        &:nth-child(3) {
          a {
            background-image:url('/images/home/featured/accessories.jpg');
          }   
        }
        &:last-child {
          a {
            background-image:url('/images/home/featured/deals.jpg');
          }   
        }
      }
    }
    &.right {
      padding:0 1.5rem 1.5rem 1.5rem;
      @include breakpoint (large) {
        padding:0 0 0 1.25rem;
      }
      a {
        background-image:url('/images/home/featured/gallery.jpg');
        border:1rem solid $white;
        @include breakpoint (medium) {
          border-width:1.5rem;
        }
      }
      .content {
        @include breakpoint (large) {
          padding-top:9rem;
          padding-bottom:6rem;
        }
      }
      .subtitle {
        margin-bottom:.25rem;
        @include breakpoint (large) {
          font-size:1.25rem;
          margin-bottom:.75rem;
        }
      }
      .title {

        font-size:4.5rem;
        font-weight:700;
        margin-bottom:1rem;
        @include breakpoint (large) {
          font-size:7rem;
          margin-bottom:1.75rem;
        }
      }
      .link {
        font-size:.85rem;
        display:flex;
        justify-content: center;
        align-items: center;
        font-weight:600;
        border:1px solid $white;
        margin:0 auto;
        width:80px;
        height:40px;
        @include breakpoint (large) {
          width:130px;
          height:60px;
          font-size:1rem;
        }
      }
    }
  }
}