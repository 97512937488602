#contact p,
#thanks p,
#contact li,
#thanks li {
    font-weight: 500;
    padding-top: 0px;
}

#contact h3,
#thanks h3 {
    font-size: 2rem;
    margin-bottom: 0px;
}

#contact p.red {
    color: $primary-color;
    font-size: 1rem;
    font-weight: 600;
}

#contact, #thanks {
  // page header
  .content-header {
    background-color:$gray6;
    @include breakpoint (medium) {
      background-image:url('/images/banners/contact.jpg');
      background-size:cover;
      background-position: center;
      text-align:center;
      display:flex;
      align-items: center;
      justify-content: center;
      margin-bottom:2rem;
      padding:0;
    }
    @include breakpoint (large) {
      height:240px;
    }
    h1 {
      color:$white;
      font-size: 2.25rem;
      font-weight: 700;
      letter-spacing: .2rem;
    }
    .content-header-overlay {
      background-color:rgba(0,0,0,.5);
      padding-top:2rem;
      padding-bottom:2rem;
      width:100%;
      display:flex;
      align-items: center;
      justify-content: center;
      height:100%;
    }
  }

  .page-content.padded.extra {
    @include breakpoint (small only) {
      padding-top:0;
    }
  }
}

#contact {
  margin-bottom:3rem;
  h3,h4{
    text-transform: uppercase;
  }
  h3 {
    font-size:1.65rem;
  }
  h4 {
    font-size:1.55rem;
    text-transform: uppercase;
    margin-top:1.5rem;
    span {
      font-weight:400;
    }
  }
  .page-header {
    padding-top:2rem;
    text-align:center;
    @include breakpoint (large) {
      padding-top:2rem;
      padding-bottom:2.5rem;
    }
    h1 {
      font-family:$black-font-family;
      font-size: 2.25rem;
      font-weight: 700;
      letter-spacing: .2rem;
      @include breakpoint (large) {
        font-size:2.75rem;
      }
    }
  }
  .page-content {
    background-color:$whiteF2;
    border:1px solid $grayC;
    padding:2rem;
  }
  select {
    text-transform: capitalize;
  }
  .page-description {
    text-align: center;
    background-color:$grayF1;
    padding-top:2rem;
    padding-bottom:2rem;
    p {
      font-size:1.35rem;
      font-weight:300;
    }
  }
  .iframe-container.google-maps {
    iframe {
      width:100%;
    }
  }

  .location {
    margin-bottom:1rem;
  }
  .icon-container {
    a {
      margin-right:1rem;
    }
  }


  #form-right {
    padding-bottom:2rem;
    .fa {
      font-size: 2.25rem;
    }
    h3 {
      font-family:$body-font-family;
      font-size: 1.2rem;
    }
    ul {
      margin-bottom:.5rem;
      li {
        color:$black;
      }
    }
    .location, .sales {
      padding-bottom:2rem;
    }
    .sales {
      text-transform: uppercase;
    }
  }
}


#contact.warranty-submit {
  a {
    text-decoration:underline;
  }
  .permission {
    margin-bottom:2rem;
  }
  .g-recaptcha {
    margin-bottom:1rem;
  }
  .red {
    color:$gray6;
  }
  .divider {
    margin-top:2rem;
    margin-bottom:1rem;
    height:1px;
    background-color:$grayC;
  }
}


#contact.sponsorship {
  .page-description {
    p {
      font-size:1.15rem;
      font-weight:400;
    }
    h3 {
      margin-bottom:1rem;
    }
    .row {
      max-width:90rem;
    }
  }
  input {
    &::-webkit-input-placeholder { /* Edge */
      font-style: italic;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      font-style: italic;
    }
    &::placeholder {
      font-style: italic;
    }
  }
  .disclaimer {
    margin-top:1rem;
    margin-bottom:1rem;
  }
}
// custom quote
#contact.custom-quote {
  margin-bottom:3rem;
  .page-header {
    padding-top:2rem;
    text-align:center;
  }
  .page-content {
    background-color:$whiteF2;
    border:1px solid $grayC;
    padding:2rem;
  }
  select {
    text-transform: capitalize;
  }
}