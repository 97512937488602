#packages {
  // filter //////////////////
  .filter {
    background-color:$whiteF1;
    border:1px solid $grayC;
    margin-bottom:2rem;
    .head {
      .title {
        color:$white;
        background-color:$black;
        text-transform: uppercase;
        font-weight:700;
        text-align:center;
        padding:1rem;
        font-size:1.25rem;
      }
    }
    .accordion {
      &-title {
          color:$white;
          background-color:$black;
          text-transform: uppercase;
          font-weight:700;
          text-align:center;
          padding:1rem;
          font-size:1.25rem;
          line-height:1.7;
          border:0;
          &:before {
            margin-top:-1rem;
          }
        
      }
      li {
        margin-bottom:0;
      }
    }
    .content {
      padding:1.25rem;
    }
    ul {
      list-style: none;
      margin:0;
      li {
        margin-bottom:.25rem;
      }
    }
    .vehicle {
      border-bottom:1px solid $grayC;
      padding-bottom:1rem;
      margin-bottom:1rem;
      &-make {
        a {
          font-size:1.15rem;
          font-weight:700;
          color:$black;
          text-transform: uppercase;
          &:hover {
            color:$primary-color;
          }
          i {
            color:$primary-color;
          }
        }
        ul {
          margin-left:2rem;
        }
      }
      &-models {
        display:none;
        &.active {
          display:block;
        }
        .active {
          color:$pink;
        }
      }
    }
    .cta {
      text-align:center;
      p {
        font-weight:500;
        line-height:1.1;
      }
      .button {
        padding-left:0;
        padding-right:0;
        width:100%;
      }
    }
  }

  // filter //////////////////
  .promotion {
    text-align:center;
    padding:0;
    &-item {
      margin-bottom:1rem;
    }
  }
}