#wheels, #wheel-brands, #gallery {
  // header
  h1 {
    text-transform:uppercase;
    @include breakpoint (medium) {
      color:$white;
    }
    @include breakpoint (large) {
      font-size:2.25rem;
      font-weight:700;
      letter-spacing:.2rem;
    }
  }
  .content-header {
    @include breakpoint (medium) {
      background-image:url('/images/banners/wheels-banner.jpg');
      background-size:cover;
      background-position: center;
      text-align:center;
      padding-top:2rem;
      padding-bottom:2rem;
      display:flex;
      align-items: center;
      justify-content: center;
    }
    @include breakpoint (large) {
      height:240px;
    }
  }

  .page-content.padded.extra {
    @include breakpoint (small only) {
      padding-top:0;
    }
  }


  .listing-search-bar {
    #wheel-filter-search {
      //padding-left:.25rem;
      ul {
        margin:0;
        list-style: none;
        @include breakpoint (xlarge) {
          display:flex;
          justify-content: space-between;
        }
        > li {
          display:inline-block;
          margin-bottom:.35rem;
          @include breakpoint (xlarge) {
            //display
          }
          &:last-child {
            margin-right:0;
            a {
              width:96px;
              @include breakpoint (xlarge) {
                width:40px;
              }
            }
          }
        }
      }
    }
    a {
      width:46px;
      height:52px;
      display:flex;
      align-items:center;
      justify-content:center;
      background-color:$grayF4;
      color:$gray6;
      font-weight:500;
      font-size:1.25rem;
      @include breakpoint (large) {
        width:40px;
        height:40px;
        font-size:1rem;
      }
      &:hover,&.active-letter {
        background-color:$primary-color;
        color:$white;
      }
      &.disable-letter {
        pointer-events: none;
        opacity:.25;
      }
    }

    // mobile
    .mobile-only-slide-filter-search {
      .row > .column {
        padding-left:.35rem;
        padding-right:.35rem;
        &:first-child {
          padding-left:0;
          a,button {
            background-color:$primary-color;
            color:$white;
          }
        }
        &:last-child {
          padding-right:0;
          a,button {
            background-color:transparent;
            border:1px solid $grayEF;
            color:$black;
            display:flex;
            justify-content: space-between;
            text-align: left;
            align-items: center;
            > div {
              width:60%;
            }
          }
        }
      }
      a,button {
        width: auto;
        font-size: .75rem;
        font-weight: 700;
        letter-spacing: 0;
        padding: 0 1rem;
      }
      button {
        width:100%;
        height:52px;
      }
    }
  }

  // listing
  .products-list {
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:4rem;
    }

    .hover-item {
      position: relative;
      margin-bottom:2rem;
      &:hover {
        img {
          transform:scale(1.025);
        }
      }
    }

    .sale-item {
      background: linear-gradient(135deg, $primary-color 0, $primary-color 40%, transparent 40%, transparent 100%);
      position: absolute;
      top: 0;
      left: 0;
      width: 100px;
      height: 100px;
      text-align: left;
      padding: 0.5rem;
      padding-right:2rem;
      text-transform: uppercase;
      line-height: 1.1;
      color:$white;
      font-weight:700;
      font-size:.9rem;
    }

    .info {
      text-align:left;
      color:$black;
    }

    .image {
      background-color:$grayF4;
      margin-bottom:1rem;
      text-align:center;
    }

    .product-name {
      display:flex;
      align-items:flex-start;
      margin-bottom:.5rem;
      span {
        display:inline-block;
      }
      .new {
        width:56px;
        height:28px;
        background-color:$primary-color;
        font-size:.9rem;
        font-weight:700;
        color:$white;
        margin-right:.75rem;
        text-align:center;
        text-transform: uppercase;
        line-height:2;
      }
      .brand-and-name {
        text-align:left;
        font-weight:700;
        letter-spacing:.1rem;
        text-transform: uppercase;
        line-height:1.25;
      }
    }
    .specs,.price,.shipping {
      font-family:$detail-font-family;
    }
    .specs {
      font-size:.85rem;
      text-transform: uppercase;
      .sku {
        color:$gray9;
      }
    }

    .price {
      text-align:left;
      color:$black;
      text-transform: uppercase;
      font-size:1.25rem;
      font-weight:700;
      line-height:1.25;
      margin-top:.5rem;
      .price-title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: .75rem;
      }
      .symbol,.value {
        font-weight:700;
        font-size:1.25rem;
      }
      .price-title,.set {
        font-size:.75rem;
        font-weight:600;
      }
      &.original-price {
        .symbol,.value {
          font-weight:500;
        }
        .value {
          text-decoration: line-through;
        }
      }
      &.sales-price {
        color:$primary-color;
      }
    }

    .shipping {
      text-transform: uppercase;
      color:$gray9;
      font-size:.75rem;
      font-weight:600;
      letter-spacing:.05rem;
    }
  }

  .pagination-container {
    
  }
  .pagination {
    border-bottom:1px solid $grayC;
    margin-bottom:3rem;
    padding-bottom:2rem;
    font-weight:500;
    text-transform: uppercase;
    .current {
      background-color:$primary-color;
    }
  }

  // footer
  .financing {
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:4rem;
    }
  }

  .brand-information {
    margin-bottom:2rem;
    @include breakpoint (large) {
      margin-bottom:3rem;
    }
    h5 {
      font-size: 1.8rem;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: .2rem;
    }
    p {
      font-size:.9rem;
      font-weight:500;
      line-height:2;
    }
    .title {
      text-transform: uppercase;
      color:$black;
      font-weight:600;
    }
    ul {
      margin:0;
      list-style:none;
      li {
        display:inline-block;
      }
    }
  }
}

// mobile
.sticky-container {
  // cta
  .top {
    background-color:$white;
    padding:1rem 1.25rem;
    padding-bottom:0;
    position:relative;
  }

  .listing-search-bar,.brand-letter-search {
    a {
      width:46px;
      height:52px;
      display:flex;
      align-items:center;
      justify-content:center;
      background-color:$grayF4;
      color:$gray6;
      font-weight:500;
      font-size:1.25rem;
      @include breakpoint (large) {
        width:40px;
        height:40px;
        font-size:1rem;
      }
      &:hover,&.active-letter {
        background-color:$primary-color;
        color:$white;
      }
      &.disable-letter {
        pointer-events: none;
        opacity:.25;
      }
    }
    .mobile-only-slide-filter-search {
      .row > .column {
        padding-left:.35rem;
        padding-right:.35rem;
        &:first-child {
          padding-left:0;
          a,button {
            background-color:$primary-color;
            color:$white;
          }
        }
        &:last-child {
          padding-right:0;
          a,button {
            background-color:transparent;
            border:1px solid $grayEF;
            color:$black;
            display:flex;
            justify-content: space-between;
            text-align: left;
            align-items: center;
            > div {
              width:60%;
            }
          }
        }
      }
      a,button {
        width: auto;
        font-size: .75rem;
        font-weight: 700;
        letter-spacing: 0;
        padding: 0 1rem;
        margin-bottom:0;
      }
      button {
        width:100%;
        height:52px;
      }
    }
  }
  // header
  .main.cta-added {
    box-shadow:none;
    border-bottom:1px solid $black;
  }
  .content-header {
    background-color:$white;
    box-shadow: 0px 5px 15px -5px $black;
    h1 {
      font-size: 1.15rem;
      letter-spacing: 0.05rem;
      font-weight: 700;
    }
  }
}

/*
// series title
#wheels {
  padding-top:2rem;
  .series-anchor {
    display: block;
    position: relative;
    top: -200px;
    visibility: hidden;
  }
  .series-logo {
    border-bottom:1px solid $grayA;
    margin-bottom:1rem;
    padding-bottom:.5rem;
    @include breakpoint (medium) {
      margin-bottom:1.5rem;
    }
    img {
      max-width:200px;
    }
  }
  .products-series, .products-series .products-list {
    margin-left:auto;
    margin-right:auto;
  }
}

// wheels listing
#wheels {
  .products-list {
    text-align:center;
    .product-information {
      margin-bottom:1rem;
      color:$gray3;
      text-transform: uppercase;
      @include breakpoint (medium) {
        margin-bottom:2rem;
      }
      @include breakpoint (large) {
        margin-bottom:3rem;
      }
      .wheel-name {
        font-weight:700;
      }
      .wheel-sizes {
        font-size:.9rem;
        font-weight:600;
      }
    }
    &-none {
      text-align:center;
      font-size:1.25rem;
      span {
        font-weight:600;
      }
    }
  }
}

// Fitment
#wheels,#wheel {
  .page-header.fitment-header {
    font-size:1rem;
    @include breakpoint (small only) {
      padding-top:2rem;
    }
    h1 {
      line-height:1;
      font-size:1.85rem;
      font-size:700;
    }
    span {
      @include breakpoint (small only) {
        display:block;
      }
    }
    .fitment-bar-container {
      @include breakpoint (medium) {
        display:flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .fitment-bar {
      padding-left:.625rem;
      padding-right:.625rem;
      @include breakpoint (medium) {
        padding-left:.9375rem;
        padding-right:.9375rem;
      }
      &.right {
        @include breakpoint (medium) {
          max-width:300px;
        }
      }
    }
    .fitment-header-info {
      @include breakpoint (medium) {
        display:flex;
        flex-direction: column;
        justify-content: center;
      }
      .vehicle-size {
        font-size:1.35rem;
        font-weight:100;
      }
    }
    .vehicle-display {
      display:flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include breakpoint (medium) {
        border-left:1px solid $grayA;
      }
    }
    .vehicle-display {
      text-align: center;
      padding-left:1rem;
      padding-right:1rem;
    }
    .vehicleUpdate {
      padding-left:1rem;
      padding-right:1rem;
    }
    .fitment-change {
      color:$gray5;
      text-transform: uppercase;
      font-weight: 400;
    }
  }
}*/