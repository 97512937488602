.logo {
  display: inline-block;
  img {
    @include breakpoint(medium down) {
      max-width: 180px;
    }
  }
}

.sticky-container {
  z-index: 10;
  height:67px;
  @include breakpoint (medium) {
    height:75px;
  }
  @include breakpoint (large) {
    height:109px;
  }
  .is-stuck {
    .content-header {
      box-shadow:none;
      .parallax {
        display:none;
      }
      .filter-header {
        text-align:center;
        //padding:2rem 0 1rem;
        margin-bottom:-1px;
        h1 {
          margin-bottom:0;
        }
      }
    }
    > div:nth-child(2) {
      padding-top:2rem;
    }
    > div:last-child {
      padding-bottom:1rem;
      box-shadow: 0 10px 10px 0 $gray6;
    }
  }
}

.header {
  &::after {
    position: absolute;
    content: ' ';
    right: 0;
    bottom: -1rem;
    left: 0;
    height: 1rem;
    z-index: 1;
    /*background: url('/images/home/tear/black-top.png') bottom center repeat-x;*/
  }
  &.cta-added::after {
    bottom:auto;
  }
  background-color: rgba(0,
  0,
  0,
  1);
  .row {
    max-width:90rem;
  }
  a {
    text-transform: uppercase;
    font-weight:400;
  }
  .top-bar {
    padding-top: 0.5rem;
    a {
      text-transform: uppercase;
    }
    
    @include breakpoint (medium) {
      &-left,&-right {
        width:100%;
      }
      &-right {
        float:none;
        margin:0 auto;
      }
    }
    /*@media screen and (min-width:1200px) {
      &-left,&-right {
        width:80%;
      }
    }*/
  }
  &--sm {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .nav-bar {
    @include breakpoint (large) {
      border-top: 1px solid $medium-gray;
      margin-top:.5rem;
    }
  }
  &-top {
    padding:1rem;
    @include breakpoint (medium) {
      padding:1rem 1.5rem;
    }
    @include breakpoint (large) {
      padding-bottom:.5rem;
      padding-left:0;
      padding-right:0;
    }
  }
  // hamburger
  .menu-icon {
    width:30px;
    &:after {
      height:3px;
      box-shadow:0 10px 0 $white, 0 20px 0 $white;
    }
    &:hover::after {
      background-color:$gray9;
      box-shadow:0 10px 0 $gray9, 0 20px 0 $gray9;
    }
  }
}
.header-left {
  display:flex;
  justify-content: space-between;
  @include breakpoint (medium down) {
    img {
      max-width:70px;
    }
  }
}
.header-right {
  display:flex;
  align-items:center;
  justify-content:space-between;
}
.hamburger {
  float: right;
  padding: 1rem;
  @include breakpoint(large up) {
    display: none;
  }
}

.top-bar-grid {
  //display: grid;
  //grid-template-rows: 50% 50%;
  @media screen and (min-width:1200px) {
    padding-top:.5rem;
  }
  &__top {
    display: flex;
    justify-content: space-between;
    //border-bottom: 1px solid $medium-gray;
    &.padded.short {
      padding-top:0;
      padding-bottom:0;
      @media screen and (min-width:1104px) {
        padding-top:.5rem;
        padding-bottom:.5rem;
      }
    }
  }
}
// phone
.nav-phone {
  text-align: center;
  font-family:$black-font-family;
  color:$grayC;
  line-height:1;
  margin-left:7%;
  .phone {
    font-size:4.75rem;
    font-weight:900;
    text-transform: uppercase;
    span {
      color:$red;
    }
  }
  .subtitle {
    font-style: italic;
    text-transform: capitalize;
    font-size:1.25rem;
    font-weight:700;
  }
}

.nav-phone-sm {
  padding-left: 0.3rem;
  font-weight: 700;
  @include breakpoint(medium only) {
    font-size: 1.2rem;
    font-weight: 500;
  }
  @include breakpoint(large up) {
    display: none;
  }
  &__txt {
    color: $primary-color;
    line-height: 1;
    text-transform: uppercase;
    @include breakpoint(small only) {
      font-size: 0.9rem;
    }
    @include breakpoint(medium only) {
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
  &__number {
    color: $white;
    white-space: nowrap;
    font-weight: 700;
    @include breakpoint(small only) {
      font-size: 0.9rem;
    }
  }
}

// nav cta
.nav-ctas {
  display:flex;
  justify-content: flex-end;
  text-align:center;
  letter-spacing:.1rem;
  a {
    padding-left:.5rem;
    padding-right:.5rem;
    color:$white;
    display:block;
    margin-left:.5rem;
    &:hover {
      color:$red;
      .icon {
        color:$red;
        .snipcart-items-count {
          color:$red;
        }
      }
    }
  }
  .icon {
    color:$gray9;
    font-size:1.75rem;
    transition: all .2s ease-in-out;
    &.checkout-icon {
      height:48px;
      position:relative;
      img,svg {
        height:40px;
        width:40px;
        transition:all .2s ease-in-out;
        fill:$primary-color;
      }
      &:hover {
        svg {
          fill:$gray9;
        }
      }
      .snipcart-items-count {
        transition: all .2s ease-in-out;
        position: absolute;
        left:0;
        right:0;
        bottom:.35rem;
        color:$gray9;
        font-weight:700;
        font-size:1.25rem;
      }
    }
  }
}

.nav-top-links {
  padding-top:.5rem;
  @include breakpoint(medium down) {
    display: none;
  }
  a {
    color: $secondary-color;
    font-size: 0.9rem;
    font-weight: 700;
    padding: 0.5rem;
  }
  &__icon {
    font-size: 1.1rem;
    color: $secondary-color;
  }
}

.header-bottom {
  border-top:1px solid $gray6;
}
/*.menu {
  @include breakpoint(large up) {
    display: flex;
    justify-content: space-between;
  }
  li {
    a {
      color: $white;
      font-size: 1.3rem;
      font-weight: 700;
      padding: 0.5rem 1rem;
      @include breakpoint(large up) {
        font-size: 1rem;
        padding: 1rem;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
}*/

@media screen and (max-width: 1023px) {
  .header {
    padding: 0;
  }
  .menu>li {
    display: inherit;
  }
  .dropdown.menu .submenu {
    position: relative;
    border: 0;
  }
}


// nav
.header .header-nav {
  border-top:1px solid $gray6;
  @include breakpoint (large) {
    padding-top:1.25rem;
    padding-bottom:1.25rem;
  }
  #responsive-menu {
    max-width:75rem;
    margin:0 auto;
    @include breakpoint (medium down) {
      display:none;
      .is-drilldown {
        max-width:none!important;
      }
    }
    .vertical-rule-left > ul {
      @include breakpoint (large) {
        display:flex;
        justify-content:space-between;
      }
    }
    a {
      color:$white;
      font-size:1.15rem;
      letter-spacing:.1rem;
      width:100%;
      padding:0;
      background-color:transparent;
      &:hover {
        color:$red;
      }
    }
  }

  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top:38px;
    padding:1rem;
    border-top-color:$primary-color;
    background-color:$black;
    left:0;
    right:auto;
    margin-left:0;
  }
  .dropdown.menu.vertical > li.opens-left > a::after {
    display:none;
  }
}

// nav mobile
.header .header-nav {
  .hamburger {
    button {
      width:22px;
    }
  }
  #responsive-menu .is-drilldown {
    padding-top:1rem;
    padding-bottom:1rem;
    .menu > li:not(.menu-text) > a {
      padding:.5rem 1.5rem;
    }
    &-submenu {
      padding-top:1rem;
      padding-bottom:1rem;
      > li:not(.menu-text) > a {
        padding:.5rem 1.5rem;
        display:inline-block;
        line-height:1;
      }
    }
  }
}
.header {
  // cart
  .snipcart-checkout {
    height:48px;
  position:relative;
    img,svg {
      height:33px;
      width:33px;
      transition:all .2s ease-in-out;
      fill:$primary-color;
    }
    &:hover {
      svg {
        fill:$gray9;
      }
    }
    .snipcart-items-count {
      transition: all .2s ease-in-out;
      position: absolute;
      left:0;
      right:0;
      bottom:.85rem;
      color:$gray9;
      font-weight:700;
      font-size:1rem;
      text-align:center;
    }
  }
}

// promo
header .header-promo {
  background-color:$whiteF2;
  color:$black;
  text-transform: uppercase;
  text-align:center;
  letter-spacing:.1rem;
  font-weight:400;
  padding-top:.5rem;
  padding-bottom:.5rem;
  font-size:.85rem;
  @include breakpoint (medium) {
    padding-top:1.25rem;
    padding-bottom:1.25rem;
    font-size:1rem;
  }
  .promo {
    line-height:1.5;
  }
  .title,a {
    font-weight:600;
  }
  a {
    text-decoration: underline;
  }
}