.hp .new {
  text-align:center;
  text-transform: uppercase;
  padding-top:2rem;
  padding-bottom:1rem;
  @include breakpoint (large) {
    padding-bottom:3rem;
  }
  .title {
    padding-bottom:.25rem;
    @include breakpoint (medium) {
      padding-bottom:1rem;
    }
  }
  h2 {
    font-family:$black-font-family;
    letter-spacing:.1rem;
    font-weight:700;
    font-size:3rem;
    @include breakpoint (large) {
      font-size:4.25rem;
    }
  }
  .listing {
    @include breakpoint (small only) {
      max-width:94%;
      margin:0 auto;
    }
  }
  .column-block {
    padding-left:10px;
    padding-right:10px;
  }
  a {
    border:1px solid $grayC;
    padding:.75rem .5rem 1.5rem;
    display:block;
    color:$black0;
    font-weight:700;
    line-height:1;
    @include breakpoint (large) {
      padding:2rem 1rem;
    }
    &:hover {
      color:$primary-color;
    }
  }
  .image {
    margin-bottom:1rem;
    @include breakpoint (medium) {
      margin-bottom:2rem;
    }
  }
  .brand {
    font-family:$regular-font-family;
    font-size:.75rem;
    margin-bottom:.25rem;
    @include breakpoint (medium) {
      font-size:1.15rem;
      margin-bottom:.5rem;
    }
  }
  .name {
    font-family:$black-font-family;
    font-size:1rem;
    @include breakpoint (medium) {
      font-size:1.75rem;
    }
  }
  &-prev, &-next {
    position: absolute;
    display: block;
    line-height: 0px;
    font-size: 1.5rem;
    cursor: pointer;
    background: transparent;
    color: $black0;
    top: 45%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    transition:all .3s ease-in-out;
    @include breakpoint (large) {
      font-size:2rem;
    }
    &:hover {
      color:$grayC;
    }
  }
  &-prev {
    left:-3%;
  }
  &-next {
    right:-3%;
  }
}