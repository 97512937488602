#page {
  .page-header {
      font-size: 2.5rem;
      font-weight: 700;
      font-family:"Roboto Condensed";
    display:flex;
    align-items:center;
    justify-content: center;
    margin-bottom:1rem;
    background-size:cover;
    @include breakpoint (large) {
      padding-bottom: 3rem;
      height:240px;
      margin-bottom:3rem;
    }
    h1 {
      @include breakpoint (large) {
        font-size:2.5rem;
      }
      color:$white;
    }
  }
  .page-content {
    h4 {
      font-size:1.25rem;
    }
  }
}