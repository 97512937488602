.hp {
  .services-banner {
    display: grid;
    display: -ms-grid;
    background-color: $black;
    grid-template-columns: 1fr;
    grid-template-rows: 40% 50%;
    -ms-grid-columns: 1fr;
    -ms-grid-rows: 40% 50%;
    border-bottom:2px solid $primary-color;

    @include breakpoint(medium up) {
      grid-template-columns: 50% 50%;
      grid-template-rows: 1fr;
      -ms-grid-columns: 50% 50%;
      -ms-grid-rows: 1fr;
    }

    ul {
      font-family:$black-font-family;
    }

    &-bkg {
      display: none;

      @include breakpoint(medium up) {
        display: block;
        max-height: 500px;
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
    }

    &-left {
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      display: flex;
      justify-content: center;
      align-items: center;

      h2 {
        margin: 0;
        font-size: 4rem;
        padding-bottom: 2rem;
      }

      a {
        font-size: 1.2rem;
      }

      .button {
        border-radius: 5px;
      }

      @include breakpoint(medium down){
        h2 {
          font-size: 2.5rem;
        }
        a {
          font-size: 0.9rem;
        }
      }

      &__btn {
        color: $black;
        background-color: $medium-gray;
        border-radius: 50%;
        height: 70px;
        width: 70px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding-left: 18px;
      }

      @include breakpoint(small down) {
        text-align: center;
      }
    }

    &-right {
      grid-column: 1 / 2;
      grid-row: 2 / 3;
      -ms-grid-row: 2;
      -ms-grid-column: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @include breakpoint(medium up) {
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }
    }

    &-header {
      color: $white;
      text-align: left;
      font-size: 2.5rem;
      margin-top: 1rem;
      font-family:$black-font-family;
      font-weight:700;

      .button {
        border-radius: 5px;
      }

      @include breakpoint(medium up) {
        margin-top: 0;
      }

      @include breakpoint(large up) {
        font-size: 4rem;
      }

      @include breakpoint(small down) {
        text-align: center;
      }

      .num-container {
        display: inline-block;

        .font-circle-container {
          background-color: red;
          border-radius: 50%;
          width: 100%;
          text-align: center;
          padding: 0px;
          width: 80px;
          height: 80px;
          line-height: 80px;
    
          @include breakpoint(medium down) {
            width: 50px;
            height: 50px;
            line-height: 50px;
          }
        }
        
        .number-font {
          font-size: 3.4rem;
          display: flex;
          justify-content: center;
        
          @include breakpoint(medium down) {
            font-size: unset;
          }
        }
      }
    }

    &-txt {
      color: $white;
      font-weight: 300;
      font-size: 1.6rem;
    }
  }

  .services-list {
    color: $white;
    list-style: none;
    margin: 0;
    border-top: 1px solid $primary-color;
    padding-top: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 1.5rem;
    font-weight: 700;

    li {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 1.5rem;

      @include breakpoint(medium down) {
        font-size: 1.1rem;
      }

      .fas {
        color: $primary-color;
        margin-right: 0.5rem;
      }
    }
  }

  .services-num-list {
    color: $medium-gray;
    margin: 0;
    font-weight: 900;
    list-style: none;
    padding-bottom: 1.5rem;

    @include breakpoint(medium down) {
      padding-top: 1.5rem;
    }

    li {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 2rem;

      @include breakpoint(medium down) {
        font-size: 1.4rem;
      }

      .fas {
        color: $primary-color;
        margin-right: 0.5rem;
      }
    }
  }
}