#gallery.results {
  .content-header {
    @include breakpoint (medium) {
      background-image:url('/images/banners/gallery.jpg');
    }
  }
  // listing
  .listing {
    //background-color:$grayE;
    //border:1px solid $grayC;
    @include breakpoint (large) {
      padding:1.75rem 0;
    }
    // sorting
    .accordion {
      background-color:transparent;
    }
    #gallery-sorting {
      #gallery-sort {
        background-color:transparent;
        padding:0;
        select {
          font-weight:400;
          background-color:$white;
          text-transform: uppercase;
          @include breakpoint (medium) {
            padding:.5rem;
          }
        }
      }
    }
    h3 {
      text-transform: none;
    }
    .accordion-filter {
      &__container {
        display:flex;
        @include breakpoint (large) {
          justify-content: flex-start;  
          align-items:center;
        }
      }
      &__header {
        font-size:1rem;
        display:inline-block;
        margin:0;
      }
      &__dropdown {
        max-width:140px;
      }
    }
    .top {
      //padding: 0 1.75rem;
      //padding-bottom: 2rem;
    }
    
    
    // gallery list 
    .gallery-list {
      @include breakpoint (large) {
        padding-left:1rem;
        padding-right:1rem;
      }
      img {
        position: static;
        width:100%;
      }
      .info {
        background-color:$grayF4;
        text-transform: uppercase;
        color:$black;
      }
      .image {
        background-position: center;
        background-size:cover;
        @include breakpoint (medium) {
          max-height:180px;
        }
        @include breakpoint (large) {
          max-height:390px;
        }
        img {
          opacity:0;
        }
      }
      .description {
        padding:.9rem;
        font-weight:500;
        font-family:$body-font-family;
        font-size:.85rem;
        letter-spacing:.05rem;
        margin-bottom:1rem;
        @include breakpoint (large) {
          margin-bottom:2rem;
        }
        .vehicle {
          font-size:1rem;
          font-family:$body-font-family;
          letter-spacing:.05rem;
          font-weight:700;
        }
      }
    }
    
    // pagination
    .pagination-container {
      border-top:1px solid $gray9;
      border-bottom:1px solid $gray9;
      margin:0 1.75rem;
      margin-bottom:2rem;
      height:76px;
      display:flex;
      align-items: center;
      justify-content: center;
      .current {
        font-weight:700;
      }
      a {
        margin-bottom:0;
        box-shadow:none;
      }
      ul {
        margin-bottom:0;
      }
    }
    
    // quote
    .quote {
      padding-top:0;
      padding-bottom:0;
      .column {
        padding:0 1.75rem;
      }
      a {
        color:$red;
        margin-bottom:0;
      }
    }
  }

  // mobile
  .mobile-only-slide-filter {
    .row > .column {
      padding-left:.35rem;
      padding-right:.35rem;
      &:first-child {
        padding-left:0;
        a,button {
          background-color:$primary-color;
          color:$white;
        }
      }
      &:last-child {
        padding-right:0;
        a,button {
          background-color:transparent;
          border:1px solid $gray3;
          color:$black;
          display:flex;
          justify-content: space-between;
          text-align: left;
          align-items: center;
          > div {
            width:60%;
          }
        }
      }
    }
    a,button {
      width: auto;
      font-size: .75rem;
      font-weight: 700;
      letter-spacing: 0;
      padding: 0 1rem;
      width:100%;
      height:52px;
      display:flex;
      align-items: center;
      @include breakpoint (large) {
        height:40px;
      }
    }
  }

  /*
    .row > .column {
      padding-left:0;
      padding-right:0;
      
      //&:first-child {
      //  padding-left:0;
      //  .button {
      //    background-color:$primary-color;
      //    color:$white;
      //  }
      //}
        .button {
          background-color:$primary-color;
          //border:1px solid $grayEF;
          color:$white;
          display:flex;
          justify-content: space-between;
          align-items:center;
          text-align: left;
          > div {
            width:60%;
          }
        }
    }
    .button {
      width: 100%;
      height:46px;
      font-size: .75rem;
      font-weight: 700;
      letter-spacing: 0;
      padding: 0 1rem;
    }
  }*/
}

  // mobile
  .sticky-container {
    // cta
    .top {
      background-color:$white;
      padding:1rem 1.25rem;
      padding-bottom:0;
      position:relative;
    }
  
    .gallery-filter {
      .mobile-only-slide-filter {
        .row > .column {
          padding-left:.35rem;
          padding-right:.35rem;
          &:first-child {
            padding-left:0;
            a,button {
              background-color:$primary-color;
              color:$white;
            }
          }
          &:last-child {
            padding-right:0;
            a,button {
              background-color:transparent;
              border:1px solid $grayEF;
              color:$black;
              display:flex;
              justify-content: space-between;
              text-align: left;
              align-items: center;
              > div {
                width:60%;
              }
            }
          }
        }
        a,button {
          width: auto;
          font-size: .75rem;
          font-weight: 700;
          letter-spacing: 0;
          padding: 0 1rem;
          margin-bottom:0;
        }
        button {
          width:100%;
          height:52px;
        }
      }
    }
    // header
    .main.cta-added {
      box-shadow:none;
      border-bottom:1px solid $black;
    }
    .content-header {
      background-color:$white;
      box-shadow: 0px 5px 15px -5px $black;
      h1 {
        font-size: 1.15rem;
        letter-spacing: 0.05rem;
        font-weight: 700;
      }
    }
  }