// quote banner
.quote {
  padding:1.5rem 0;
  text-align: center;
  @include breakpoint (medium) {
    padding-top:2.5rem;
    padding-bottom:3rem;
  }
  @include breakpoint (large) {
    padding-bottom:4rem;
    padding-top:3.5rem;
  }
  .column {
    padding:0 1.5rem;
  }
  a {
    display:block;
    background-color:$black0;
    display:block;
    padding:1.15rem;
    text-transform: uppercase;
    font-weight:400;
    line-height:1.2;
    letter-spacing:.1rem;
    @include breakpoint (medium) {
      padding:2rem;
    }
  }
  .content {
    border:2px solid $gray6;
    padding:1.25rem;
    @include breakpoint (medium) {
      padding:2rem;
    }
  }
  .title {
    font-family:$regular-font-family;
    color:$white;
    font-size:1.45rem;
    margin-bottom:1.5rem;
    @include breakpoint (medium) {
      font-size:1.85rem;
    }
  }
  .subtitle {
    font-family:$black-font-family;
    font-weight:700;
    text-decoration: underline;
    font-size:1.25rem;
    line-height:1.5;
    letter-spacing:.25rem;
    @include breakpoint (medium) {
      font-size:1.95rem;
    }
    @include breakpoint (large) {
      font-size:2.75rem;
      line-height:1.2;
    }
  }
}