// side filter (results.cfm page)
#gallery.results {
  .filter {
    padding-top:1.75rem;
    .vehicle {
      margin-bottom:2rem;
      select {
        font-size:.75rem;
        font-weight:500;
        text-transform: uppercase;
        letter-spacing:.05rem;
      }
    }
  }
  // filter sidebar

  /*
  .filter {
    background-color:$grayE;
    border:1px solid $grayC;
    margin-bottom:1.5rem;
    h4 {
      letter-spacing:.15rem;
      text-transform: capitalize;
      font-size:1.25rem;
      margin-bottom:0;
    }
    i {
      color:$gray9;
      font-size:3rem;
      &:hover {
        color:$red;
      }
      &.fa-caret-down {
        color:$primary-color;
      }
    }
    ul {
      margin:0;
      li {
        list-style: none;
        margin-top:.25rem;
        margin-bottom:.25rem;
      }
    }
    label {
      font-size:1.1rem;
    }
    .filter-title {
      display:flex;
      justify-content: space-between;
      align-items: center;
    }
    .product-filters {
      padding-left:1rem;
      padding-right:1rem;
      padding-bottom:1rem;
      .product-filter {
        border-bottom:1px solid $gray9;
        padding-bottom:1rem;
        margin-bottom:1rem;
        label {
          width:80%;
          overflow:hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
          line-height:1;
          margin-bottom:-.15rem;
        }
      }
    }
    .filter-button {
      color:$primary-color;
      font-size:1.15rem;
      text-transform: capitalize;
      font-weight:700;
      &:hover {
        color:$gray9;
      }
    }
    // vehicle select
    .vehicle {
      padding-bottom:1rem;
      &-title {
        text-align:center;
        color:$white;
        background-color:$primary-color;
        padding-top:.6rem;
        padding-bottom:.6rem;
        margin-bottom:1rem;
        padding-left:1rem;
        padding-right:1rem;
        @include breakpoint (large) {
          padding-left:0;
          padding-right:0;
          padding-top:1.25rem;
          padding-bottom:1.25rem;
        }
        h4 {
          color:$white;
          text-transform: uppercase;
          display:flex;
          align-items:center;
          justify-content: space-between;
          cursor: pointer;
          @include breakpoint (large) {
            justify-content:center;
          }
          i {
            color:$white;
          }
        }
      }
      &-active, &-select {
        padding-left:1rem;
        padding-right:1rem;
      }
      &-active {
        display:flex;
        justify-content: center;
        align-items: center;
        text-align:center;
        margin-bottom:1rem;
        > div {
          &:first-child {
            color:$black;
            font-size:1.15rem; 
            letter-spacing:.15rem;
            text-transform: uppercase;
            font-weight:700;
            @include breakpoint (large) {
              width:80%;
            }
            span {
              margin-right:.25rem;
            }
          }
          &:last-child {
            display:none;
            @include breakpoint (large) {
              display:block;
              width:20%;
              text-align:right;
            }
          }
        }
      }
      &-select {
        background-color: transparent;
        select {
          text-transform: capitalize;
          font-weight: 400;
        }
        .vehicle-trim {
          border-bottom:1px solid $gray9; 
        }
      }
    }
    
    // filter active
    .filter-active {
      padding-left:1rem;
      padding-right:1rem;
      padding-bottom:1rem;
      a {
        background-color:$grayC;
        color:$black;
        border-radius:5px;
        padding:.25rem .5rem;
        font-weight:400;
        i {
          color:$primary-color;
          font-size:1.25rem;
          transition: all .3s ease-in-out;
        }
        &:hover {
          background-color:$primary-color;
          color:$white;
          i {
            color:$white;
          }
        }
      }
      ul {
        li {
          display:inline-block;
          margin-bottom:.5rem;
        }
      }
      &-clear {
        border-bottom:1px solid $gray9;
        padding-bottom:1rem;
        a {
          color:$primary-color;
          font-weight:700;
          font-size:1.15rem;
          text-transform: capitalize;
          border:none;
          background-color:transparent;
          padding:0;
        }
      }
      .filter-active-title {
        margin-bottom:1rem;
        h4 {
          display:flex;
          align-items:center;
          justify-content: space-between;
          width:100%;
        }
      }
    }
    
    // tire
    .product-filters .product-filter.filter-gallery-size {
      margin-bottom:3rem;
    }
    
    // search field
    .product-filters .product-filter.filter-gallery-search {
      border-bottom:0;
      display:flex;
      justify-content: space-between;
      align-items: center;
      label {
        padding-left:1rem;
      }
      input {
        margin-bottom:0;
        background-color:transparent;
        border-top:0;
        border-left:0;
        border-right:0;
        box-shadow: none;
      }
      button {
        background-color:$primary-color;
        width:100px;
        height:50px;
        display:flex;
        font-weight:700;
        letter-spacing:.1rem;
        justify-content: center;
        align-items: center;
        color:$white;
        text-transform: uppercase;
        box-shadow: 5px 5px 0px $gray6;
      }
      .search-right {
        padding-right:1rem;
      }
    }
    
  } // filter sidebar
  */
}

// accordion
#gallery.results {
  /*
  .accordion {
    background-color:transparent;
    &-title {
      border:0;
      font-family:$heading-font-family;
      text-transform: uppercase;
    }
    &-item {
      @include breakpoint (medium down) {
        &:before {
          content:'';
          display:block;
          border-top:2px solid $gray3;
          margin:0 1rem;
        }
      }
    }
    &-content {
      background-color:transparent;
    }
  }
  */
}


