#packages {
  .row {
    max-width:90rem;
  }
  // header
  h1 {
    text-transform:uppercase;
    @include breakpoint (medium) {
      color:$white;
    }
    @include breakpoint (large) {
      font-size:2.25rem;
      font-weight:700;
      letter-spacing:.2rem;
    }
  }
  .content-header {
    @include breakpoint (medium) {
      background-image:url('/images/banners/wheels-banner.jpg');
      background-size:cover;
      background-position: center;
      text-align:center;
      padding-top:2rem;
      padding-bottom:2rem;
      display:flex;
      align-items: center;
      justify-content: center;
    }
    @include breakpoint (large) {
      height:240px;
    }
  }
}

// specials listing (vehicle packages
#packages.specials {
  .quote {
    padding:0;
    a {
      padding:.85rem 0;
      font-weight:700;
      font-size:1rem;
      border-radius: 0;
      background-color:$primary-color;
      &:hover {
        background-color:$gray9;
      }
    }
  }
}