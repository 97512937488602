.footer {
  background-color:$black0;
  font-size:.95rem;
  font-weight:400;
  border-top:1px solid $grayC;
 
  h5 {
    color:$white;
    font-size:1.15rem;
    font-weight:500;
    margin-bottom:0;
    letter-spacing:.1rem;
    font-family:$regular-font-family;
  }
  .dash {
    background-color:$red;
    width:32px;
    height:3px;
    margin-right:1.15rem;
  }
  .title {
    display:flex;
    align-items:center;
  }
  // footer cta
  &-ctas {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    border-bottom:12px solid $red;
    background-color:$white;
    @include breakpoint (medium) {
      flex-direction:row;
      padding-top:1rem;
      padding-bottom:1rem;
    }
    @include breakpoint (large) {
      padding-top:2rem;
      padding-bottom:2rem;
    }
    .footer-cta {
      position: relative;
      width:100%;
      @include breakpoint (large) {
        &:after {
          position:absolute;
          content:'';
          height:70%;
          width:1px;
          background-color:$grayC;
          top:10%;
          bottom:10%;
        }
      }
      @include breakpoint (small only) {
        border-bottom:1px solid $grayC;
        &:last-child {
          border-bottom:0;
        }
      }
    }
    a {
      display:flex;
      justify-content:flex-start;
      align-items: center;
      font-family:$regular-font-family;
      padding-left:10%;
      padding-top:1rem;
      padding-bottom:1rem;
      @include breakpoint (medium) {
        justify-content: center;  
        padding:0;
      }
    }
    .container {
      display:flex;
    }
    .circle {
      background-color:$red;
      color:$white;
      width:70px;
      height:70px;
      border-radius: 50px;
      display:flex;
      justify-content: center;
      align-items: center;
      font-weight:100;
      font-size:1.75rem;
      @include breakpoint (medium only) {
        width:50px;
        height:50px;
        font-size:1.25rem;
      }
    }
    .text {
      line-height:1.2;
      display:flex;
      flex-direction: column;
      justify-content: center;
      margin-left:1rem;
    }
    .title,.subtitle {
      color:$black;
    }
    .title {
      text-transform: uppercase;
      font-weight:900;
      font-size:1.25rem;
    }
    .subtitle {
      font-weight:400;
    }
  } 
  
  // links
  &-links {
    @include breakpoint (small only) {
      padding:1.5rem;
    }
    .row {
      border-bottom:1px solid $gray3;
      padding-top:1rem;
      padding-bottom:1rem;
      @include breakpoint (medium) {
        padding-top:2rem;
        padding-bottom:2rem;
      }
      @include breakpoint (large) {
        padding-top:3rem;
        padding-bottom:3rem;
      }
    }
    a {
      display:block;
      letter-spacing:.1rem;
      text-transform: uppercase;
      color:$grayC;
      padding-bottom:.35rem;
      &:hover {
        color:$red;
      }
    }
    ul {
      list-style: none;
      margin-left:2rem;
      @include breakpoint (medium) {
        margin-left:3.15rem;
      }
    }
    .title {
      margin-bottom:1rem;
    }
    .dash {
      @include breakpoint (small only) {
        width:12px;
      }
    }
  }
  
  // information 
  &-information {
    text-transform: uppercase;
    color:$grayC;
    letter-spacing:.1rem;
    @include breakpoint (small only) {
      padding-left:1.5rem;
      padding-right:1.5rem;
      padding-bottom:1.5rem;
    }
    .row {
      border-bottom:1px solid $gray3;
      padding-top:1rem;
      padding-bottom:1rem;
      @include breakpoint (medium) {
        padding-top:2rem;
        padding-bottom:2rem;
      }
      @include breakpoint (large) {
        padding-top:3rem;
        padding-bottom:3rem;
      }
    }
    .logo {
      margin-bottom:2rem;
    }
    .title {
      margin-bottom:.5rem;
    }
    .company {
      font-size:1.15rem;
      font-weight:600;      
    }
    a {
      font-size:1.75rem;
      display:inline-block;
      margin-right:.5rem;
    }

    .social {
      margin-top:1rem;
    }
    .third-party {
      display:inline-block;
      img {
        max-height:70px;
      }
    }
  }
  
  // copyrights
  &-copyrights {
    text-align:center;
    text-transform: uppercase;
    font-family:$regular-font-family;
    color:$gray9;
    font-size:.85rem;
    padding-bottom:2rem;
    padding-left:1.5rem;
    padding-right:1.5rem;
    @include breakpoint (medium) {
      padding-left:.5rem;
      padding-right:.5rem;
      padding-top:1.5rem;
    }
    p {
      @include breakpoint (small only) {
        font-size:.75rem;
      }
    }
    a {
      color:$gray9;
      &:hover {
        color:$red;
      }
    }
    br {
      @include breakpoint (small only) {
        display:none;
      }
    }
  }
}





















