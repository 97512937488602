.hp .front-promo {
  padding-bottom:2rem;
  text-align:center;
  @include breakpoint (medium) {
    padding-bottom:3rem;
  }
  .promo {
    display:flex;
    justify-content: center;
    color:$black0;
    text-transform: uppercase;
    font-size:.85rem;
    letter-spacing: .1rem;
    font-weight:400;
    flex-direction: column;
    line-height:1.5;
    @include breakpoint (large) {
      font-size:1.25rem;
      flex-direction: row;
      line-height:1.7;
    }
    .title {
      font-weight:600;
      margin-right:.5rem;
      i {
        margin-right:.25rem;
        @include breakpoint (large) {
          margin-right:1rem;
        }
      }
      span {
        font-family:$body-font-family;
        font-weight:400;
      }
    }
    a {
      text-decoration: underline;
    }
  }
}