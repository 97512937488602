.hp {
  .hp-welcome {
    padding-top: 3rem;
    padding-bottom: 3rem;
    p,h2 {
      font-family:$black-font-family;
    }
    h2 {
      font-weight:700;
    }
    &-header {
      font-size: 3rem;
      color: $primary-color;
      text-align: center;
      position: relative;
      padding-bottom: 0.5rem;

      .black-text {
        color: $black;
      }
    }

    &-header:after {
      content: '';
      position: absolute;
      border-bottom: 1px solid $grayC;
      bottom: 0;
      width: 60%;
      max-width: 400px;
      left: 50%;
      transform: translateX(-50%);
    }

    &-flex {
      display: flex;
      flex-direction: row;

      @include breakpoint(medium down) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      a {
        width: 30%;
        text-align: right;

        @include breakpoint(medium down) {
          width: unset;
          text-align: center;
          padding: 2em 0px;
        }
      }
    }

    &-txt {
      color: $black0;
      font-weight: 400;
      margin-bottom: 2rem;
    }

    &-txt-bold {
      font-weight: 700;
    }

    &-txt-link {
      color: $primary-color;
      text-decoration: underline;
      font-weight: 700;
    }

    &-txt-flex {
      display: flex;
      font-size: 1.1rem;
      flex-direction: column;
      justify-content: flex-start;
      width: 40%;
      max-width: 900px;
      padding: 0em 2.5%;

      @include breakpoint(medium down) {
        width: unset;
        padding: 0em 7%;
      }
    }

    &-right-flex {
      display: flex;
      width: 30%;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      position: relative;
      @include breakpoint(medium down) {
        justify-content: center;
        width: unset;
        align-items: unset;
      }
    }

    &-right-overlay-link {
      height: 100%;
      width: 100%;
      cursor: pointer;
      position: absolute;
    }

    &-btn {
      color: $white;
      background-color: $primary-color;
      font-size: 1.3rem;
      border-radius: 5px;
      padding: 0.8rem 1.5rem;
    }
  }
}