.breadcrumbs {
  font-size:.85rem;
  padding-top:2rem;
  //padding-bottom:2rem;
  font-weight:700;
  text-transform: uppercase;
  line-height:1.25;
  @include breakpoint (medium) {
    font-size:1rem;
  }
  a {
    color:$primary-color;
    &:hover {
      color:$black;
    }
  }
}

#products {
  .breadcrumbs {
    @include breakpoint (small only) {
      padding-bottom:0;
    }
  }
}