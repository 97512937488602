.hp .banners {
  // finance banners 
  .finance {
    img {
      width:100%;
    }
    .column {
      padding-bottom:.75rem;
      @include breakpoint (medium) {
        padding-bottom:1.15rem;
        &:first-child {
          padding-right:.575rem;
        }
        &:nth-child(2) {
          padding-left:.575rem;
        }
      }
      &:last-child {
        @include breakpoint (small only) {
          padding-bottom:0;
        }
      }
    }
  }
}