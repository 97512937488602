.hp .fitment {
  background-image:url('/images/home/fitment.jpg');
  background-size:cover;
  background-position:center;
  text-align:center;
  text-transform: uppercase;
  line-height:1;
  color:$white;
  letter-spacing:.1rem;
  padding-top:1.5rem;
  padding-bottom:1.5rem;
  @include breakpoint (medium) {
    padding-left:0;
    padding-right:0;
  }
  @include breakpoint (large) {
    padding-top:3.5rem;
    padding-bottom:3.5rem;
  }
  .subtitle {
    font-family:$regular-font-family;
    font-weight:500;
    font-size:1.1rem;
    margin-bottom:.75rem;
    @include breakpoint (medium) {
      margin-bottom:.5rem;
    }
    @include breakpoint (large) {
      font-size:1.25rem;
    }
  }
  .title {
    font-family:$black-font-family;
    font-weight:700;
    font-size:2.15rem;
    @include breakpoint (medium) {
      font-size:4.5rem;
    }
    @include breakpoint (large) {
      font-size:7rem;
    }
  }
  .content {
    margin-bottom:1rem;
  }
  .ymm {
    background-color:rgba(0,0,0,.7);
    padding:2rem;
    display:flex;
    flex-direction: column;
    @include breakpoint (medium) {
      padding:2.5rem;
      flex-direction: row;
    }
  }
  #fitment-vehicle-select, #wheels-fitemnt-vehicle-select, #tires-fitment-vehicle-select {
    width:100%;
  }
  #icv_vehicle-select {
    @include breakpoint (large) {
      display:flex;
    }
    > select {
      height:46px;
      margin-bottom:.5rem;
      @include breakpoint (large) {
        margin-bottom:0;
        margin-left:1rem;
      }
      &:first-child {
        margin-left:0;
      }
    }
  }
  #app {
    display:flex;
    flex-direction:column;
    width:100%;
    @include breakpoint (medium) {
      flex-direction:row;
    }
    label {
      width:100%;
      margin-bottom:1rem;
      @include breakpoint (medium) {
        padding-right:1rem;
        margin-bottom:0;
      }
    }
  }
  select {
    margin-bottom:0;
    text-transform: uppercase;
    font-size:1.15rem;
    letter-spacing:.1rem;
    height:auto;
  }
  .vehicle-select {
    width:100%;
    background-color:transparent;
    margin-bottom:.75rem;
    @include breakpoint (medium) {
      padding-right:1rem;
      margin-bottom:0;
    }
    &.vehicle-submit {
      padding-right:0;
      @include breakpoint (medium) {
        max-width:130px;  
      }
      .button {
        margin-bottom:0;
        display:flex;
        border-radius: 0;
        width:100%;
        height:46px;
        justify-content: center;
        align-items: center;
        font-size:1.25rem;
        @include breakpoint (medium) {
          font-size:1rem;
        }
      }
    }
  }

  // search bar //////////////@extend// tabs
  .tabs {
    border:0;
    background-color:transparent;
  }
  .tabs-title {
    border-bottom: 35px solid $grayB;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    height: 0;
    position:relative;
    transition:all .3s ease-in-out;
    margin-right:-1rem;
    width:27%;
    @include breakpoint (medium) {
      border-left-width: 15px;
      border-right-width: 15px;
      border-bottom-width: 40px;
      width:25%;
    }
    @include breakpoint (large) {
      border-left-width: 25px;
      border-right-width: 25px;
      border-bottom-width: 56px;
    }
    &:nth-child(1) {
      border-bottom-color:$gray4;
      z-index:4;
    }
    &:nth-child(2) {
      border-bottom-color:$gray57;
      z-index:3;
    }
    &:nth-child(3) {
      border-bottom-color:$gray79;
      z-index:2;
    }
    &:nth-child(4) {
      border-bottom-color:$grayB;
      z-index:1;
    }
    &.is-active {
      border-bottom-color:$primary-color;
      z-index:5;
    }
    &:hover {
      //border-bottom-color:$gray3;
    }
    &:focus {
      border-bottom-color:$primary-color;
    }
    > a {
      position: absolute;
      height:40px;
      display:flex;
      align-items: center;
      justify-content:center;
      padding:0;
      width:100%;
      color:$white;
      text-transform: uppercase;
      font-weight:600;
      background-color:transparent;
      font-size:.65rem;
      letter-spacing:0;
      @include breakpoint (medium) {
        font-size:.75rem;
      }
      @include breakpoint (large) {
        height:56px;
        font-size:1rem
      }
      span {
        padding-left:.25rem;
        padding-right:.25rem;
      }
    }
  }

  .tabs-panel {
    padding:0;
  }

  // tabs content
  .tabs-content {
    background-color:transparent;
    border:0;
    select {
      margin-bottom:0;
      text-transform: uppercase;
    }
    .row > .column {
      @include breakpoint (large) {
        padding-right:5px;
      }
      &:last-child {
        padding-right:0;
      }
      label {
        margin-bottom:5px;
        @include breakpoint (large) {
          margin-bottom:0;
        }
      }
    }
  }

  // tabs content - fitment search
  #app {
    @include breakpoint (large) {
      display:flex;
    }
    label {
      width: 100%;
      margin-bottom:5px;
      @include breakpoint (large) {
        //padding-right:5px;
        margin-bottom:0;
      }
      &:last-child {
        padding-right:0;
      }
    }
  }
  #wheels-vehicle-select,#tires-vehicle-select {
    .vehicle-select.vehicle-submit {
      display:none;
    }
  }

  // tabs content - wheels spec select
  #wheels-specs-select {
    padding:2rem;
    background-color:rgba(0,0,0,.7);
    @include breakpoint (medium) {
      padding:2.5rem;
    }
    .row > .column {
      @include breakpoint (large) {
        //padding-right:5px;
        padding-right:1rem;
      }
      &:last-child {
        padding-right:0;
      }
      label {
        margin-bottom:5px;
        @include breakpoint (large) {
          margin-bottom:0;
        }
      }
    }

    .button {
      margin-bottom:0;
      width:100%;
      padding:.675rem 1rem;
      border-radius:0;
      height:46px;
      transition: all .3s ease-in-out;
      &[disabled] {
        opacity:.75;
        background-color:$grayC;
        color:$gray9;
        border-color:$gray9;
      }
    }
  }
  

  .fitment-by-size-select {
    display:flex;
    flex-direction:column;
    @include breakpoint (large) {
      flex-direction: row;
    }
    label, select {
      width:100%;
      @include breakpoint (large) {
        padding-left:1rem;
      }
    }
    >label:first-child {
      margin-left:0;
    }
    label {
      margin-bottom:.5rem;
      @include breakpoint (large) {
        margin-bottom:0;
      }
    }
    select {
      height:46px;
    }
    button {
      @include breakpoint (large) {
        margin-left:1rem;
      }
    }
  }
}



/*.hp .fitment {
  background-color:$blue;
  padding-top:1rem;
  padding-bottom:2rem;
  .title {
    text-align:center;
  }
  h3 {
    line-height:1.4;
    a {
      color:$white;
      transition: all .3s ease-in-out;
      &:hover {
        color:$gray6;
      }
    }
  }
  .tabs,.tabs-content {
    background:transparent;
    border:0;
  }
  .tabs {
    display:flex;
    justify-content:center;
    @include breakpoint (large) {
      margin-bottom:1rem;
    }
    .tabs-title {
      //width:100%;
      //@include breakpoint (large) {
      //  width:50%;
      //}
      //&:first-child {
      //  border-right:1px solid $white;
      //}
      //&:last-child {
      //  border-left:1px solid $white;
      //}
      > a {
        color:$white;
        font-size:1.3rem;
        text-transform: uppercase;
        font-weight:600;
        background:transparent;
        padding:0;
        border-bottom:2px solid transparent;
        transition: all .3s ease-in-out;
        @include breakpoint (large) {
          font-size:2.25rem;
        }
        &:hover {
          background-color:transparent;
        }
      }
      &.is-active {
        a {
          border-radius:2px;
          //background:$black;
          border-bottom-color: $white;
        }
      }
    }
  }
  .links {
    display:flex;
    justify-content:center;
    align-items:center;
    list-style: none;
    line-height:1;
    margin-bottom:1rem;
    @include breakpoint (large) {
      margin-bottom:1.5rem;
    }
    li {
      //width:100%;
      //@include breakpoint (large) {
      //  width:50%;
      //}
      //&:first-child {
      //  border-right:1px solid $white;
      //}
      //&:last-child {
      //  border-left:1px solid $white;
      //}
      > a,span {
        color:$white;
        font-size:1.3rem;
        text-transform: uppercase;
        font-weight:600;
        background:transparent;
        padding:0;
        border-bottom:2px solid transparent;
        transition: all .3s ease-in-out;
        border-bottom-color: $white;
        @include breakpoint (large) {
          font-size:2.25rem;
        }
        &:hover {
          background-color:transparent;
          border-bottom-color:$blue2;
        }
      }
      span {
        border-bottom-color:transparent;
        &:hover {
          color:$white;
          border-bottom-color:transparent;
        }
      }


      &.is-active {
        a {
          border-radius:2px;
          //background:$black;
          border-bottom-color: $white;
        }
      }
    }
  }
  .divider {
    font-size:1.3rem;
    color:$white;
    font-weight:600;
    padding-left:.5rem;
    padding-right:.5rem;
    line-height:1;
    @include breakpoint (large) {
      font-size:2.25rem;
    }
  }
  .tabs-panel {
    @include breakpoint (large) {
      padding:0;
    }
  }
  #icv_vehicle-select {
    @include breakpoint (large) {
      display:flex;
      flex-wrap: nowrap;
    }
    > select {
      border-radius: 3px;
      @include breakpoint (large) {
        margin:.5rem;
      }
    }
  }
}
*/